'use client'

import styled from 'styled-components'

import { Box, ContentText, fontWeights, px2rem, spacing } from '@fortum/elemental-ui'

import type { UspEntry, UspSectionEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import { truncateMultiLineText } from '@/shared/style/styleMixins'

import { ContentfulImage } from '../ContentfulImage'
import type { IconId } from '../Icon'
import { Icon } from '../Icon'
import { StyledLink } from '../StyledLink'

export type UspProps = UspEntry & {
  colors?: ColorProps<never, 'text' | 'heading' | 'link'>
  viewMode: UspSectionEntry['viewMode']
}

const defineMediaFile = (
  props: Pick<UspProps, 'icon' | 'image' | 'viewMode'>,
  textColor: string,
) => {
  const { icon, image, viewMode } = props
  switch (viewMode) {
    case 'icon':
      return icon ? <Icon icon={icon as IconId} color={textColor} size={48} /> : null
    case 'image':
      return image ? (
        <ContentfulImage
          wrapperProps={{
            position: 'relative',
            width: '100%',
            height: { default: px2rem(96), l: px2rem(128) },
          }}
          imageEntry={image}
          style={{ objectFit: 'contain', objectPosition: 'top center' }}
        />
      ) : null
  }
}

/**
 * Default component designed for unique selling propositions.
 */
export const Usp = ({ title, content, link, icon, image, viewMode, colors = {} }: UspProps) => {
  const theme = useTheme()
  const textColor = colors.heading ?? theme.colors.textLarge
  const linkColor = colors.link ?? theme.colors.textLink
  const media = defineMediaFile({ icon, image, viewMode }, textColor)

  return (
    <Box
      data-testid="usp-box"
      key={title}
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={spacing.xxxs}
      textAlign="center"
      position="relative"
    >
      {media}
      <UspHeading weight={fontWeights.medium} color={textColor} size="xl">
        {title}
      </UspHeading>
      <ContentText color={textColor}>{content}</ContentText>
      {link && (
        <StyledLink href={getLinkEntryUrl(link)} mt={spacing.xxxs} color={linkColor}>
          {link.label}
        </StyledLink>
      )}
    </Box>
  )
}

const UspHeading = styled(ContentText)`
  ${truncateMultiLineText(3)}
`
