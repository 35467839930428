'use client'

import type { MouseEventHandler } from 'react'

import { ContentText, px2rem, spacing } from '@fortum/elemental-ui'

import type { IconId } from '@/shared/components/Icon'
import { Icon } from '@/shared/components/Icon'
import { StyledLink } from '@/shared/components/StyledLink'
import { useTheme } from '@/shared/hooks/useTheme'
import { getFocusColor, getHoverColor } from '@/shared/utils/colors'

type KeyLinkProps = {
  label?: string | null
  href?: string
  icon?: string | null
  dataTestId?: string
  onClickHandler?: () => void
  isFixedHeight?: boolean
  focus?: {
    background: string
    border: string
  }
  hover?: {
    background: string
    border: string
  }
  isSelected?: boolean
}

export const KeyLink = ({
  label,
  href,
  icon,
  dataTestId,
  onClickHandler,
  isFixedHeight = true,
  focus,
  hover,
  isSelected,
}: KeyLinkProps) => {
  const theme = useTheme()
  const { backgroundPrimary, brandElemental } = theme.colors
  const borderWidth = px2rem(2)

  const justifyContent = (() => {
    if (isFixedHeight) {
      if (icon) {
        return 'space-between'
      }
      return 'space-around'
    }
    return 'flex-start'
  })()

  return (
    <StyledLink
      display="flex"
      border={`${borderWidth} solid ${brandElemental}`}
      borderRadius={spacing.xxxs}
      flexDirection="column"
      noUnderline
      background={isSelected ? theme.colors.backgroundTertiary : backgroundPrimary}
      noUnderlineHover
      href={href}
      scroll={false}
      alignItems="center"
      gap={spacing.xxs}
      p={spacing.xxs}
      width="100%"
      height={isFixedHeight ? px2rem(127) : '100%'}
      justifyContent={justifyContent}
      hover={{
        background: hover?.background || getHoverColor(theme, backgroundPrimary),
        border: `${borderWidth} solid ${hover?.border || getHoverColor(theme, brandElemental)}`,
      }}
      focus={{
        background: focus?.background || getFocusColor(theme, backgroundPrimary),
        border: `${borderWidth} solid ${focus?.border || getFocusColor(theme, brandElemental)}`,
      }}
      active={{
        background: hover?.background || getHoverColor(theme, backgroundPrimary),
        border: `${borderWidth} solid ${getHoverColor(theme, brandElemental)}`,
      }}
      data-testid={dataTestId}
      onClick={onClickHandler as MouseEventHandler<HTMLAnchorElement>}
    >
      <Icon
        icon={icon as IconId}
        size={48}
        color={isSelected ? theme.colors.textInverted : 'currentColor'}
      />
      <ContentText
        color={isSelected ? theme.colors.textInverted : 'currentColor'}
        textAlign="center"
      >
        {label}
      </ContentText>
    </StyledLink>
  )
}
