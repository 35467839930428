'use client'

import { Col, Row, spacing } from '@fortum/elemental-ui'

import { Carousel } from '@/shared/components/Carousel'
import { Section } from '@/shared/components/Section'
import { SectionError } from '@/shared/components/SectionError'
import { StyledHeading } from '@/shared/components/StyledHeading'
import { Usp } from '@/shared/components/Usp'
import type { UspSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'
import { useClientSideConfs } from '@/shared/providers/appEnv'
import { rejectNil } from '@/shared/utils/array'

export type UspSectionProps = UspSectionEntry

type Colors = {
  background: string
  heading: string
  text: string
  link: string
  carousel: {
    dot: string
    dotActive: string
    button: string
  }
}

const useVariantColors = (variant: UspSectionProps['colorVariant']): Colors => {
  const { colors } = useTheme()
  switch (variant) {
    case 'secondary':
      return {
        background: colors.backgroundSecondary,
        heading: colors.textLarge,
        text: colors.textPrimary,
        link: colors.textLink,
        carousel: {
          dot: colors.textDisabled,
          dotActive: colors.brandPrimary,
          button: colors.brandPrimary,
        },
      }
    case 'inverted':
      return {
        background: colors.backgroundTertiary,
        heading: colors.textInverted,
        text: colors.textInverted,
        link: colors.textInverted,
        carousel: {
          dot: colors.textDisabled,
          dotActive: colors.textInverted,
          button: colors.textInverted,
        },
      }

    default:
    case 'default':
      return {
        background: colors.backgroundPrimary,
        heading: colors.textLarge,
        text: colors.textPrimary,
        link: colors.textLink,
        carousel: {
          dot: colors.textDisabled,
          dotActive: colors.brandPrimary,
          button: colors.brandPrimary,
        },
      }
  }
}

/**
 * Unique selling points section.
 */
export const UspSection = (props: UspSectionProps) => {
  const { title, mobileVariant, viewMode, colorVariant = 'default' } = props
  const clientSideConfs = useClientSideConfs()
  const items = props.uspSectionItemsCollection?.items
    ? rejectNil(props.uspSectionItemsCollection.items)
    : []
  const count = items.length
  const tabletColumnSize = count % 2 != 0 ? 4 : 6
  const variantColors = useVariantColors(colorVariant)

  const isMobileVariantCarousel = mobileVariant === 'carousel'

  if (count < 2 || count > 4) {
    return (
      <SectionError
        source="USP section supports only 2 to 4 items."
        name={props.name || undefined}
        appEnv={clientSideConfs.appEnv}
      />
    )
  }

  const usps = items.map((item, i) => (
    <Col key={i} xxs={12} s={tabletColumnSize} xl={12 / count}>
      <Usp
        viewMode={viewMode}
        {...item}
        colors={{
          text: variantColors.text,
          heading: variantColors.heading,
          link: variantColors.link,
        }}
      />
    </Col>
  ))

  return (
    <Section
      pv={{ default: spacing.m, l: !title ? spacing.xl : spacing.m }}
      backgroundColor={variantColors.background}
      data-testid="usp-section"
    >
      {title && (
        <StyledHeading
          level={2}
          styledAs={3}
          mb={spacing.m}
          textAlign="center"
          color={variantColors.heading}
        >
          {title}
        </StyledHeading>
      )}
      <Row justifyContent="center">
        <Col
          xxs={12}
          xl={count == 2 ? 10 : 12}
          display={isMobileVariantCarousel ? { default: 'none', s: 'flex' } : undefined}
        >
          <Row
            justifyContent="center"
            alignItems="flex-start"
            rowGap={{ default: spacing.xs, s: spacing.m }}
          >
            {usps}
          </Row>
        </Col>
        {isMobileVariantCarousel && (
          <Col display={{ s: 'none' }} xxs={12}>
            <Carousel
              elementsPerSlide={1}
              colors={variantColors.carousel}
              isOnlyActiveElementsShown={true}
              cycled={true}
            >
              {usps}
            </Carousel>
          </Col>
        )}
      </Row>
    </Section>
  )
}
