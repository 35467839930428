import { useState } from 'react'

import { Box, ContentText, LazyIcon, shadows, spacing } from '@fortum/elemental-ui'

import { BasicAccordion } from '@/shared/components/BasicAccordion'
import type { IconId } from '@/shared/components/Icon'
import { RichText } from '@/shared/components/RichText'
import { StyledHeading } from '@/shared/components/StyledHeading'
import type { ProductDetailsItemEntry, ProductDetailsSectionEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

export type ProductDetailsSectionProps = ProductDetailsSectionEntry

type Props = {
  items: ProductDetailsItemEntry[]
}

/**
 * Product details section accordion for mobile viewports.
 */
export const ProductDetailsAccordionMobile = ({ items }: Props) => (
  <Box display="flex" flexDirection="column" gap={spacing.xs}>
    {items.map((item) => (
      <AccordionItem key={item.sys.id} item={item} />
    ))}
  </Box>
)

type AccordionItemProps = {
  item: ProductDetailsItemEntry
}

const AccordionItem = ({ item }: AccordionItemProps) => {
  const { id } = item.sys
  const { icon, title, contentTitle, content } = item

  const [isExpanded, setExpanded] = useState(false)

  const theme = useTheme()

  const panelId = `product-details-panel-mobile-${id}`

  return (
    <BasicAccordion
      isExpanded={isExpanded}
      onToggle={() => setExpanded(!isExpanded)}
      header={
        <Box display="flex" alignItems="center" gap={spacing.xxs}>
          <LazyIcon iconId={icon as IconId} size={48} />
          <ContentText size="l">{title}</ContentText>
        </Box>
      }
      panelId={panelId}
      headerTestId="product-details-accordion-header"
      boxProps={{
        backgroundColor: isExpanded ? theme.colors.backgroundPrimary : 'transparent',
        boxShadow: isExpanded ? shadows.m : 'none',
        borderRadius: spacing.xxxs,
      }}
      headerBoxProps={{ padding: spacing.xxs }}
    >
      <Box p={{ default: spacing.xxs, s: spacing.xs }}>
        {contentTitle && (
          <StyledHeading level={3} styledAs={2} mb={spacing.xxs}>
            {contentTitle}
          </StyledHeading>
        )}
        <RichText document={content} />
      </Box>
    </BasicAccordion>
  )
}
