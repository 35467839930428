import { useTranslations } from 'next-intl'
import styled from 'styled-components'

import type { ChipItem } from '@fortum/elemental-ui'
import { Box, Chips, IconCheck, fontSizes, spacing, styles } from '@fortum/elemental-ui'

import { useGlobalStore } from '@/shared/store/provider'
import { getHoverColor } from '@/shared/utils/colors'
import { stepEvent } from '@/shared/utils/sendGTMEvent'

import { Icon } from '../Icon'

type Category = { label?: string | null; value?: number | null }

export type ContractCategoryFilterProps = {
  /**
   * Categories from ContractProduct entry.
   */
  categories: Category[]
  /**
   * Currently active filter (Maximum one).
   */
  activeFilter?: string | null
  /**
   * Callback triggered when filter is selected. Invoked with null when reset is clicked.
   */
  setActiveFilter: (value: string | null) => void
}

/**
 * Filters for ContractListSection. Only one filter can be active at a time.
 */
export const ContractCategoryFilter = ({
  categories,
  setActiveFilter,
  activeFilter,
}: ContractCategoryFilterProps) => {
  const t = useTranslations('contractTemplateFilter')
  const filters = categories
    .filter(isCategoryValid)
    .map((category) => ({
      label: category.label as string,
      value: category.value?.toString() as string,
    }))
    .filter(isFilter)

  const { housingSize, housingType } = useGlobalStore((state) => state.housingInfo)

  if (!categories?.length) {
    return null
  }

  const handleFilterChange = (filter: string | null) => {
    setActiveFilter(filter)

    const selectedFilter = categories.find(
      (category) => category.value?.toString() === filter,
    )?.label

    stepEvent('filter_contracts', {
      filter: selectedFilter || '',
      housing_type: housingType,
      size: housingSize,
    })
  }

  return (
    <Box
      gap={spacing.xxxs}
      display="flex"
      flexDirection={{ default: 'column', s: 'row' }}
      justifyContent="flex-start"
      alignItems={{ default: 'flex-start', s: 'center' }}
    >
      <UnderlineButton
        disabled={!activeFilter}
        onClick={() => handleFilterChange(null)}
        data-testid="contract-category-filter-reset"
      >
        {t('resetAllFilters')}
      </UnderlineButton>
      <Chips
        items={filters}
        aria-label="Available filters"
        active={activeFilter ? [activeFilter] : []}
        onChange={(value) => handleFilterChange(value[0] || null)}
        activeIcon={<Icon icon={IconCheck} />}
        data-testid="contract-category-filters"
      />
    </Box>
  )
}

const isCategoryValid = (category: Category) => Boolean(category.label && category.value)

const isFilter = (filter: unknown): filter is ChipItem<string> =>
  Boolean((filter as ChipItem<string>).label && (filter as ChipItem<string>).value)

const UnderlineButton = styled.button.attrs<{ disabled: boolean }>((props) => ({
  'aria-disabled': props.disabled,
}))`
  color: ${({ disabled, theme }) => (disabled ? theme.colors.textDisabled : theme.colors.textLink)};
  border: none;
  background: none;
  text-decoration: underline;
  font-family: ${styles.fontFamily.base};
  font-size: ${fontSizes.m.default};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};

  &:hover {
    color: ${({ theme, disabled }) =>
      !disabled ? getHoverColor(theme, theme.colors.textLink) : theme.colors.textDisabled};
  }
`
